import { css } from '@emotion/css';
import $ from 'jquery';
import React from 'react';
import Modal from 'react-modal';
import ReactPageScroller from 'react-page-scroller';
import './App.css';
import Carousel from './Carousel';
import { sendEmail } from './EmailUtil';
import investmentItems from './investmentItems.json';
var _this

const mediaItems = [{
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/yahoo.svg',
  content: 'Yahoo Finance'
}, {
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/forbes.svg',
  content: 'Forbes'
}, {
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/coindesk.png',
  content: 'Coindesk'
}, {
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/bloomberg.png',
  content: 'Bloomberg'
}]

const exchangeItems = [{
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/kucoin.png',
  content: 'Kucoin'
}, {
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/ftx.png',
  content: 'FTX'
}, {
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/huobi.png',
  content: 'Huobi Global'
}, {
  logoUrl: 'https://moon-capital-1257732598.cos.accelerate.myqcloud.com/binance.png',
  content: 'Binance'
}]

const setting = {
  dragSpeed: 1.25,
  itemWidth: 272,
  itemHeight: 145,
  itemSideOffsets: 15,
}

const partnerSetting = {
  dragSpeed: 1.25,
  itemWidth: 352,
  itemHeight: 205,
  itemSideOffsets: 15,
}

const itemStyle = {
  width: `${setting.itemWidth}px`,
  height: `${setting.itemHeight}px`,
  margin: `0px ${setting.itemSideOffsets}px`
}

const partnerItemStyle = {
  width: `${partnerSetting.itemWidth}px`,
  height: `${partnerSetting.itemHeight}px`,
  margin: `0px ${partnerSetting.itemSideOffsets}px`
}


class App extends React.Component {

  constructor(props) {
    super(props)
    _this = this
    this.state = {
      height: 0,
      width: 0,
      page: 0,
      showFirstLogoText: false,
      showSecondLogoText: false,
      showThirdLogoText: false,
      firstLeft: true, secondLeft: true, thirdLeft: true,
      selectedMEOption: 0,
      interestPage: 0,
      showContact: false,
      sendingEmail: false
    }
  }

  updateWindowDimension() {
    setTimeout(() => {
      _this.setState({ height: window.innerHeight, width: window.innerWidth })
      let svg = $('#valueaddtext').get(0)
      svg.setAttribute('width', '35.5vh');
      svg.setAttribute('height', '3vh');
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension)
    window.removeEventListener("wheel", this.onWheelRoll);
    clearInterval(this.spinner)
  }

  componentDidMount() {
    this.updateWindowDimension()
    window.addEventListener("resize", this.updateWindowDimension)
    window.addEventListener("wheel", this.onWheelRoll);
    this.startSpinnin()
  }

  wheelControl = true
  onWheelRoll = (e) => {
    if (this.state.showContact)
      return
    if (e.target.className?.indexOf('Interest') >= 0)
      return
    if (this.wheelControl) {
      if (e.deltaY > 0) {
        this.wheelControl = false
        this.nextPage()
        setTimeout(() => {
          this.wheelControl = true
        }, 1500);
      } else if (e.deltaY < 0) {
        this.wheelControl = false
        this.formerPage()
        setTimeout(() => {
          this.wheelControl = true
        }, 1500);
      }
    }
  }

  formerPage = () => {
    let formerPage = this.state.page - 1
    if (formerPage < 0)
      return
    this.setState({ page: formerPage })
  }

  nextPage = () => {
    // this.setState({ page: this.state.page + 1 })
    let nextPage = this.state.page + 1
    if (nextPage > 4)
      return
    this.setState({ page: nextPage })
  }

  getProjectLoc1 = (timeControl = 0) => {
    let right = -(this.state.height - 250) / 2 * Math.sin(Math.PI / 4 + timeControl) + 200 + (this.state.height - 250) / 2 - 75
    let top = (this.state.height) / 2 - (this.state.height - 250) / 2 * Math.cos(Math.PI / 4 + timeControl) - 75
    // console.log(right)
    return { top: top, right: right }
  }
  getProjectLoc2 = (timeControl = 0) => {
    let right = -(this.state.height - 250) / 2 * Math.sin(Math.PI / 4 + Math.PI * 2 / 3 + timeControl) + 200 + (this.state.height - 250) / 2 - 75
    let top = (this.state.height) / 2 - (this.state.height - 250) / 2 * Math.cos(Math.PI / 4 + Math.PI * 2 / 3 + timeControl) - 75
    return { top: top, right: right }
  }
  getProjectLoc3 = (timeControl = 0) => {
    // this.state.height - 250
    // console.log(this.state.height)
    let right = -(this.state.height - 250) / 2 * Math.sin(Math.PI / 4 + Math.PI * 4 / 3 + timeControl) + 200 + (this.state.height - 250) / 2 - 75
    let top = (this.state.height) / 2 - (this.state.height - 250) / 2 * Math.cos(Math.PI / 4 + Math.PI * 4 / 3 + timeControl) - 75
    return { top: top, right: right }
  }
  // 150

  time = 0
  speed = Math.PI / 10000
  spinner
  startSpinnin = () => {
    this.spinner = setInterval(() => {
      let timeControl = this.time * this.speed

      document.getElementById('logo1').style.right = this.getProjectLoc1(timeControl).right + 'px'
      document.getElementById('logo1').style.top = this.getProjectLoc1(timeControl).top + 'px'
      document.getElementById('logo2').style.right = this.getProjectLoc2(timeControl).right + 'px'
      document.getElementById('logo2').style.top = this.getProjectLoc2(timeControl).top + 'px'
      document.getElementById('logo3').style.right = this.getProjectLoc3(timeControl).right + 'px'
      document.getElementById('logo3').style.top = this.getProjectLoc3(timeControl).top + 'px'

      this.time++
    }, 2);
  }


  render() {
    return (
      <div className="App" id="App">

        <div id="logo1" style={{ position: 'fixed', width: '150px', height: '150px', zIndex: 9999 }}
          className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0s;
                transition:200ms opacity 0ms;
                &.p1{
                  opacity:1;
                  transition:0s;
                  transition:200ms opacity 500ms;
                }
              `
          )} onMouseEnter={() => {
            this.setState({ showFirstLogoText: true, firstLeft: parseFloat(document.getElementById('logo1').style.left.replace('px', '')) < (this.state.height - 250) / 2 })
          }} onMouseLeave={() => {
            this.setState({ showFirstLogoText: false })
          }}
        >
          <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/WooTradeLogo.png" style={{ width: '150px', height: '150px' }} />
          <div className={"ProjectDescriptionContainer " + (this.state.showFirstLogoText ? 'display ' : '') + (this.state.firstLeft ? 'left ' : 'right ') + css(`
                  display:none;
                  &.display{
                    display:block;
                  }
              `)} >
            <div className="ProjectDescriptionMain">
              <div className="ProjectDescriptionTitle">Wootrade</div>
              <div className="ProjectDescriptionContent">Wootrade offers zero trading fees, deep liquidity, and a customizable GUI for individual and business clients. First global partner of the platform.</div>
            </div>
          </div>

        </div>
        <div id="logo2" style={{ position: 'absolute', width: '150px', height: '150px', zIndex: 9999 }}
          className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0s;
                transition:200ms opacity 0ms;
                &.p1{
                  opacity:1;transition:0s;
                  transition:200ms opacity 500ms;
                }
              `
          )} onMouseEnter={() => {
            this.setState({ showSecondLogoText: true, secondLeft: parseFloat(document.getElementById('logo2').style.left.replace('px', '')) < (this.state.height - 250) / 2 })
          }} onMouseLeave={() => {
            this.setState({ showSecondLogoText: false })
          }}
        >
          <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/OrchidLogo.png" style={{ width: '150px', height: '150px' }} />
          <div className={"ProjectDescriptionContainer " + (this.state.showSecondLogoText ? 'display ' : '') + (this.state.secondLeft ? 'left ' : 'right ') + css(`
                  display:none;
                  &.display{
                    display:block;
                  }
              `)} >
            <div className="ProjectDescriptionMain">
              <div className="ProjectDescriptionTitle">Akropolis</div>
              <div className="ProjectDescriptionContent">Akropolis offers an easy-to-use interface for automating earning DeFi yields for retail and institutional users. Asia market partner.</div>
            </div>
          </div>
        </div>
        <div id="logo3" style={{ position: 'absolute', width: '150px', height: '150px', zIndex: 9999 }}
          className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0s;
                transition:200ms opacity 0ms;
                &.p1{
                  opacity:1;transition:0s;
                  transition:200ms opacity 500ms;
                }
              `
          )} onMouseEnter={() => {
            this.setState({ showThirdLogoText: true, thirdLeft: parseFloat(document.getElementById('logo3').style.left.replace('px', '')) < (this.state.height - 250) / 2 })
          }} onMouseLeave={() => {
            this.setState({ showThirdLogoText: false })
          }}
        >
          <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/PhalaLogo.png" style={{ width: '150px', height: '150px' }} />
          <div className={"ProjectDescriptionContainer " + (this.state.showThirdLogoText ? 'display ' : '') + (this.state.thirdLeft ? 'left ' : 'right ') + css(`
                  display:none;
                  &.display{
                    display:block;
                  }
              `)} >
            <div className="ProjectDescriptionMain">
              <div className="ProjectDescriptionTitle">Phala</div>
              <div className="ProjectDescriptionContent">Phala Network is a Web3.0 compute cloud that supports data privacy while remaining trustless. Supporting in marketing and community.</div>
            </div>
          </div>
        </div>
        <div className="MainContainer" style={{ height: this.state.height + 'px', zIndex: '1' }}>
          <div className="header">
            <img src='https://moon-capital-1257732598.cos.accelerate.myqcloud.com/img/logo-w.png' className="logo" />
            <div className="Menu">
              <div className={"MenuItem" + (this.state.page == 0 ? ' selected' : '')} onClick={() => this.setState({ page: 0 })}>About</div>
              <div className={"MenuItem" + (this.state.page == 1 || this.state.page == 2 ? ' selected' : '')} onClick={() => this.setState({ page: 1 })}>Partners</div>
              <div className={"MenuItem" + (this.state.page == 3 ? ' selected' : '')} onClick={() => this.setState({ page: 3 })}>Investment</div>
              <div className={"MenuItem" + (this.state.page == 4 ? ' selected' : '')} onClick={() => this.setState({ page: 4 })}>Areas of Interest</div>
            </div>
          </div>

          <div className={'PageContainer ' + ('p' + this.state.page + ' ') + css`
            &.p0{
              transform:translateY(0)
            }
            &.p1{
              transform:translateY(-${this.state.height}px)
            }
            &.p2{
              transform:translateY(-${this.state.height * 2}px)
            }
            &.p3{
              transform:translateY(-${this.state.height * 3}px)
            }
            &.p4{
              transform:translateY(-${this.state.height * 4}px)
            }
          `} style={{}}>
            <div className="AboutPage" style={{ height: this.state.height + 'px', textAlign: 'center', width: '100%', }}>
              <div className="AboutPageContext">This is Moon Capital, making venture capital investments in early-stage projects among crypto and blockchain area.
                We're partnering with great crypto projects to push open finance to the forefront.
                In the meantime, we build bridges between projects, the community, and top tier exchanges.
              </div>
            </div>

            <div className="PartnerPage" style={{ height: this.state.height + 'px', textAlign: 'center', width: 'fit-content' }}>
              <div className="PartnerLeft" >
                <div className="PartnerLeftUpper">We partner with<br />early crypto<br />projects that push</div>
                <div className="PartnerLeftLower">the frontier of<br />open finance</div>
              </div>
            </div>
            <div className="PartnerPage2" style={{ height: this.state.height + 'px', textAlign: 'center', width: '100%', }}>
              <div className="PartnerPage2Text" >
                <span className="normal">We offer competitive</span>

                <svg id="valueaddtext" width='35.5vh' height='3vh' style={{ marginBottom: '-0.1vh', marginLeft: '1vh' }} >
                  <text x="17.75vh" y="2.9vh" textAnchor="middle" fill="none" strokeWidth="0.1vh" stroke="#fff" fontFamily="proxon"
                    letterSpacing="0.08rem">value-add services</text>
                </svg>

                {/* <svg viewBox="0 0 10 1" width="800" height="40">
                  <text x="1" y="0.98" textAnchor="middle" fontSize="1" fill="none" strokeWidth=".03" stroke="#fff" fontFamily="proxon"
                    letterSpacing="0.08rem">value-add services</text>
                </svg> */}
                <br />
                <span className="normal">with our network of partners in network.</span>
              </div>
              <div className="MediaExchangeSelector">
                <div className={"MediaExchangeOption " + (this.state.selectedMEOption == 0 ? 'selected' : '')} style={{ marginRight: '10px' }}
                  onClick={() => this.setState({ selectedMEOption: 0 })}>Media</div>
                <div className={"MediaExchangeOption " + (this.state.selectedMEOption == 1 ? 'selected' : '')}
                  onClick={() => this.setState({ selectedMEOption: 1 })}>Exchange</div>
              </div>

              <div className="PartnerCarousel">
                <Carousel _data={this.state.selectedMEOption == 0 ? mediaItems : exchangeItems} {...partnerSetting} row={1}>
                  {
                    (this.state.selectedMEOption == 0 ? mediaItems : exchangeItems).map((i, _i) => (
                      <div
                        key={_i}
                        className='item'
                        style={{ ...partnerItemStyle }}>
                        <div className="CarouselItemContainer">
                          <img src={i.logoUrl} className="CarouselLogo" />
                          <div className="CarouselContent">{i.content}</div>
                        </div>
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </div>

            <div className="InvestmentPage" style={{ height: this.state.height + 'px', textAlign: 'center', width: '100%', }}>
              <div className="InvestmentPageTitle" >Investments</div>

              <div className="InvestmentCarousel">
                <Carousel _data={investmentItems || []} {...setting} rotate row={3}>
                  {
                    investmentItems?.map((i, _i) => (
                      <div
                        key={_i}
                        className='item'
                        style={{ ...itemStyle }}>
                        <div className="CarouselItemContainer">
                          <img src={i.logoUrl} className="CarouselLogo" alt="" />
                          <div className="CarouselContent"><a href={i.href} target="_blank" >{i.content}</a></div>
                        </div>
                      </div>
                    )) || []
                  }
                </Carousel>
              </div>
            </div>
            <div className="InterestPage" id='interestPage' style={{
              height: this.state.height / 2 + 'px', textAlign: 'center', width: '50%',
              marginLeft: 'calc(50% - 100px)',
              marginTop: this.state.height / 4 + 'px',
              marginBottom: this.state.height / 4 + 'px'
            }}  >
              <ReactPageScroller renderAllPagesOnFirstRender={true} animationTimer={100} animationTimerBuffer={0} containerHeight={this.state.height / 2 + 'px'}
                containerWidth={'100%'} pageOnChange={e => {
                }} onBeforePageScroll={e => {
                  document.getElementById('pageIndicatorItem').className = 'InterestPageIndicatorItem p' + e;

                  [0, 1, 2].forEach(i => {
                    if (i == e) {
                      document.getElementById('ast' + i).classList.remove('hidden')
                    } else {
                      document.getElementById('ast' + i).classList.add('hidden')
                    }
                    if (e == 2) {
                      document.getElementById('ast22').classList.remove('hidden')
                    } else {
                      document.getElementById('ast22').classList.add('hidden')
                    }
                  })
                  // this.setState({ interestPage: e })
                }} >
                <div className='InterestSubPage' id="subpage1">
                  <div className="InterestPageTitle">
                    Decentralized Finance
                  </div>
                  <div className="InterestPageContent">
                    We believe the cypherpunk movement will not stop at NFT and DeFi. Numerous applications will be created that touch on many other aspects of society. Social tokens and games, for example, will offer the opportunities to change the way society interacts. These are valuable applications on the internet that will be the next generation of internet hotspots.
                  </div>
                </div>
                <div className='InterestSubPage' id="subpage2">
                  <div className="InterestPageTitle">
                    Non-Fungible Token
                  </div>
                  <div className="InterestPageContent">
                    NFT presents an opportunity to enable mass value of exchange amongst creators through the blockchain and the crypto world. It is also one of the first crypto design with mainstream traction outside Bitcoin. Non-fungible tokens will be fundamental in connecting the financial and the creative aspect of the metaverse.
                  </div>
                </div>
                <div className='InterestSubPage' id="subpage3">
                  <div className="InterestPageTitle">
                    Applications
                  </div>
                  <div className="InterestPageContent">
                    We believe the cypherpunk movement will not stop short of NFT and DeFi. Numerous applications will be created that touch on many other aspects of society. Social tokens and games, for example, will present opportunities to change the way society interacts. These are applications on the internet of value that will be the next generation of internet applications.
                  </div>
                </div>
              </ReactPageScroller>
              <div className="InterestPageIndicator">
                <div id={'pageIndicatorItem'} className={"InterestPageIndicatorItem p0"}></div>
              </div>
            </div>

          </div>

          <a className="TwitterModule" href="https://twitter.com/RealMoonCapital"><img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/twitter.svg" /> </a>
          <a className="MediumModule" href="https://mooncapital.medium.com/"><img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/medium.svg" /></a>
          <div className="ContactModule" onClick={() => { this.setState({ showContact: true }) }}>
            <div className="ContactModuleInner">Contact Us</div>
          </div>

          <div className={('p' + this.state.page + ' ') + "NameFull"} style={{
          }} >
            {/* <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/img/namefull.png" style={{
                height: '100%'
              }} /> */}
            MOO<span className='hidden'>N CAP</span>ITAL
          </div>

          <div className={('p' + this.state.page + ' ') + "NameFront"} style={{

          }} >
            <span className='hidden'>MOO</span>N CAP<span className='hidden'>ITAL</span>
            {/* N CAP */}
            {/* <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/img/namefront.png" style={{
                height: '100%'
              }} /> */}
          </div>
          <div style={{

          }} className={'MoonPart ' + ('p' + this.state.page + ' ') + css(`
            &.p0{
              height: 50%;
              width: 100%;
              top: 50%;
              right:0; z-index:888;
            }
            &.p1{
              width:${this.state.height - 250}px;
              height:${this.state.height - 250}px;
              right:200px;
              top:calc(50% - ${(this.state.height - 250) / 2}px);
              z-index:1;
            }
            &.p2,&.p3{
              height: ${this.state.height - 250}px;
              width: ${this.state.height - 250}px;
              top: 125px;
              right:150px;
            }
            &.p4{
              height: ${this.state.width / 2 - 150}px;
              width: ${this.state.width / 2 - 100}px;
              top: ${(this.state.height - this.state.width / 4 + 100)}px;
              right:calc(50% + 50px);
            }
          `)}>



            <div style={{
              position: 'absolute', textAlign: 'center'
            }} className={('moonimagecontainer p' + this.state.page + ' ') + css(` 
              z-index:2;
              &.p0{
                height: 200%;
                top:0;
                width: 100%;
                left:0;
                z-index:888;
              }
              &.p1{
                height: 50%;
                width: 50%;
                top:25%;
                left:25%;
                z-index:1;
              }
              &.p2,&.p3{
                height: 100%;
                width: 100%;
                top:0;
                left:0;
              }
              &.p4{
                height: 100%;
                width: 50%;
                top:0;
                left:0;
              }
           `)}>
              <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/mdmoon.png"
                className={('MoonImage p' + this.state.page + ' ') + css(` 
                  height:100%;
                  z-index:2;
                  transition:1000ms
                `)} onClick={this.nextPage}
              />
              <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/moonbg.png"
                className={('p' + this.state.page + ' ') + css(` 
                  height:100%;
                  background: #5D6D8D;
                  filter: blur(19.3836px);
                  position:absolute;
                  left:0;
                  top:0;
                  border-radius:100%;
                  z-index:-1;
                  opacity:1;
                  transition:1000ms opacity 1000ms;
                  &.p0{
                    opacity:0;
                    transition:0ms opacity;
                    left:${this.state.width / 2 - this.state.height / 2}px
                  }

                `)}
              />
            </div>

            <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/Ellipse-md.png"
              style={{ height: '100%', position: 'absolute', left: 0, top: 0 }}
              className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0;
                transition:200ms opacity 0ms ;
                &.p1{
                  opacity:1;transition:0s;
                  transition:200ms;
                  transition-delay: 500ms ;
                }
              `
              )}
            />

            <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/astronaut1.png" draggable={false}
              id={'ast0'}
              style={{ width: '70%', position: 'absolute', left: '-10px', top: -this.state.width * 0.7 * 0.25 + 'px', zIndex: 2 }}
              className={('p' + this.state.page + ' ') + css(`
                opacity:0;
                
                transition:200ms opacity 0ms;
                &.p4{
                  opacity:1;
                  
                  transition:600ms opacity 400ms !important;
                }
                &.hidden{
                  opacity:0;
                }
              `
              )}
            />

            <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/ast2.png" draggable={false}
              id={'ast1'}

              style={{ width: '30%', position: 'absolute', left: '35%', top: -this.state.width * 0.7 * 0.2 + 'px', zIndex: 2 }}
              className={('p' + this.state.page + ' hidden ') + css(`
                opacity:0;
                
                transition:200ms opacity 0ms;
                &.p4{
                  opacity:1;
                  
                  transition:600ms opacity 400ms !important;
                }
                &.hidden{
                  opacity:0;
                }
              `
              )}
            />
            <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/ast3.png" draggable={false}
              id={'ast2'}

              style={{ width: '50%', position: 'absolute', left: '30%', top: -this.state.width * 0.7 * 0.28 + 'px', zIndex: 2 }}
              className={('p' + this.state.page + ' hidden ') + css(`
                opacity:0;
                
                transition:200ms  opacity 0ms;
                &.p4{
                  opacity:1;
                  transition:600ms opacity 400ms !important;
                }
                &.hidden{
                  opacity:0;
                }
              `
              )}
            />

            <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/ast3-shadow.png" draggable={false}
              id={'ast22'}

              style={{ width: '50%', position: 'absolute', left: '35%', top: 30 + 'px', zIndex: 2 }}
              className={('p' + this.state.page + ' hidden ') + css(`
                opacity:0;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(MicrosoftTeams-image (10).png);
                mix-blend-mode: soft-light;
                transition:200ms  opacity 0ms;
                &.p4{
                  opacity:0.6;
                  transition:600ms opacity 400ms !important;
                }
                &.hidden{
                  opacity:0;
                }
              `
              )}
            />
            {/* <div id="logo1" style={{ position: 'absolute', width: '150px', height: '150px', zIndex: 9999 }}
              className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0s;
                transition:200ms opacity 0ms;
                &.p1{
                  opacity:1;
                  transition:0s;
                  transition:200ms opacity 500ms;
                }
              `
              )} onMouseEnter={() => {
                this.setState({ showFirstLogoText: true, firstLeft: parseFloat(document.getElementById('logo1').style.left.replace('px', '')) < (this.state.height - 250) / 2 })
              }} onMouseLeave={() => {
                this.setState({ showFirstLogoText: false })
              }}
            >
              <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/WooTradeLogo.png" style={{ width: '150px', height: '150px' }} />
              <div className={"ProjectDescriptionContainer " + (this.state.showFirstLogoText ? 'display ' : '') + (this.state.firstLeft ? 'left ' : 'right ') + css(`
                  display:none;
                  &.display{
                    display:block;
                  }
              `)} >
                <div className="ProjectDescriptionMain">
                  <div className="ProjectDescriptionTitle">Wootrade</div>
                  <div className="ProjectDescriptionContent">Wootrade offers zero trading fees, deep liquidity, and a customizable GUI for individual and business clients. First global partner of the platform.</div>
                </div>
              </div>

            </div> */}

            {/* <div id="logo2" style={{ position: 'absolute', width: '150px', height: '150px', zIndex: 9999 }}
              className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0s;
                transition:200ms opacity 0ms;
                &.p1{
                  opacity:1;transition:0s;
                  transition:200ms opacity 500ms;
                }
              `
              )} onMouseEnter={() => {
                this.setState({ showSecondLogoText: true, secondLeft: parseFloat(document.getElementById('logo2').style.left.replace('px', '')) < (this.state.height - 250) / 2 })
              }} onMouseLeave={() => {
                this.setState({ showSecondLogoText: false })
              }}
            >
              <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/OrchidLogo.png" style={{ width: '150px', height: '150px' }} />
              <div className={"ProjectDescriptionContainer " + (this.state.showSecondLogoText ? 'display ' : '') + (this.state.secondLeft ? 'left ' : 'right ') + css(`
                  display:none;
                  &.display{
                    display:block;
                  }
              `)} >
                <div className="ProjectDescriptionMain">
                  <div className="ProjectDescriptionTitle">Akropolis</div>
                  <div className="ProjectDescriptionContent">Akropolis offers an easy-to-use interface for automating earning DeFi yields for retail and institutional users. Asia market partner.</div>
                </div>
              </div>
            </div>
            <div id="logo3" style={{ position: 'absolute', width: '150px', height: '150px', zIndex: 9999 }}
              className={('p' + this.state.page + ' ') + css(`
                opacity:0;transition:0s;
                transition:200ms opacity 0ms;
                &.p1{
                  opacity:1;transition:0s;
                  transition:200ms opacity 500ms;
                }
              `
              )} onMouseEnter={() => {
                this.setState({ showThirdLogoText: true, thirdLeft: parseFloat(document.getElementById('logo3').style.left.replace('px', '')) < (this.state.height - 250) / 2 })
              }} onMouseLeave={() => {
                this.setState({ showThirdLogoText: false })
              }}
            >
              <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/PhalaLogo.png" style={{ width: '150px', height: '150px' }} />
              <div className={"ProjectDescriptionContainer " + (this.state.showThirdLogoText ? 'display ' : '') + (this.state.thirdLeft ? 'left ' : 'right ') + css(`
                  display:none;
                  &.display{
                    display:block;
                  }
              `)} >
                <div className="ProjectDescriptionMain">
                  <div className="ProjectDescriptionTitle">Phala</div>
                  <div className="ProjectDescriptionContent">Phala Network is a Web3.0 compute cloud that supports data privacy while remaining trustless. Supporting in marketing and community.</div>
                </div>
              </div>
            </div> */}
          </div>
          <img src="https://moon-capital-1257732598.cos.accelerate.myqcloud.com/arrowdown.png" className={"DownArrow" + (this.state.page != 1 ? ' hidden' : '')}
            onClick={this.nextPage} />
          <div className="Footer">

          </div>
        </div>

        <Modal appElement={document.getElementById('App')}
          isOpen={this.state.showContact}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => { this.setState({ showContact: false }) }}
          style={{
            overlay: {
              zIndex: 9999,
              backgroundColor: 'rgba(0, 0, 0, 0.6)'
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              width: '752px', height: '600px',
              transform: 'translate(-50%, -50%)',
              background: 'linear-gradient(122deg, rgb(244, 235, 255), rgb(235, 254, 255))',
              borderRadius: '16px',
              overflow: 'hidden'
            }
          }}
        >
          <div className="ContactInputContainer">
            <div className="ContactIntroduction">Glad you are here!</div>
            <div className="ContactSubIntroduction">Please leave your messages and our colleagues will contact you very soon.</div>
            <input placeholder='Name' id="nameinput" />
            <input placeholder='Email' id="emailinput" />
            <textarea placeholder='Message' id="messageinput"></textarea>
            <div className="ContactButtonContainer">
              <button onClick={async () => {
                this.setState({ sendingEmail: true })
                let name = document.getElementById("nameinput").value
                let email = document.getElementById("emailinput").value
                let message = document.getElementById("messageinput").value
                const status = await sendEmail(name, email, message)
                this.setState({ sendingEmail: false })
                if (status == 1) {
                  alert("Terrible things happen and your message is detained TT")
                } else {
                  alert("Your message has been recorded. Our colleagues will contact you very soon.")
                  this.setState({ showContact: false })
                }
              }}>Submit</button>
            </div>
          </div>
        </Modal>
      </div >
    );
  }
}

export default App;
