export const sendEmail = async (name, email, message) => {
    const url = 'http://43.133.33.180:8020/send'
    const contactEmail = "contact@metopia.xyz"
    const subject = "New message from website - " + name
    const content = message + "\r\n\r\nContact: " + email
    const body = {
        to: contactEmail,
        subject: subject,
        text: content
    }
    let res = await fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    });
    let json = await res.json();
    let status = res.status
    return status
    // console.log('res::', json, status)
}