import React from 'react'



class Carousel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isDown: false,
            startX: null,
            transLeftOffset: null,
            dragSpeed: props.dragSpeed
        }
        this.cRef = React.createRef()
        this.init()
    }

    init() {
        setTimeout(() => {
            // this.handleSnap()
        }, 2000);
    }


    // mouse Down
    handleMouseDown = (e) => {
        const carousel = this.cRef.current

        e.persist()

        carousel.classList.add('active')

        const _startX = e.pageX - carousel.offsetLeft
        const _transLeftOffset = this.giveMeIntValOf(carousel.firstChild.style.transform)
        this.setState({
            isDown: true,
            startX: _startX,
            transLeftOffset: _transLeftOffset
        }, () => {

            // handeling reset the transition
            const { startX, transLeftOffset, dragSpeed } = this.state

            const x = e.pageX - carousel.offsetLeft
            const walk = (x - startX) * dragSpeed
            for (let child of carousel.children) {
                child.style.cssText = `
                transform: translateX(${transLeftOffset + walk}px);
                transition: transform 0.0s ease-in-out;
              `;
            }

        });
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleSnap)
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimension)
    }

    // mouse Leave
    handleMouseLeave = (e) => {
        this.handleSnap()
    }


    // mouse Up
    handleMouseUp = (e) => {
        this.handleSnap();
    }


    // mouse Move
    handleMouseMove = (e) => {
        const { isDown, startX, transLeftOffset, dragSpeed } = this.state
        const carousel = this.cRef.current

        if (!isDown) return
        e.preventDefault()

        const x = e.pageX - carousel.offsetLeft
        const walk = (x - startX) * dragSpeed

        // carousel.firstChild.style.transform = `translateX(${transLeftOffset + walk}px)`;
        for (let child of carousel.children) {
            child.style.transform = `translateX(${transLeftOffset + walk}px)`;
        }
    }


    // handle Snap To Sides
    handleSnap = () => {
        // const { isDown, startX, transLeftOffset } = this.state
        const { _data, itemWidth, itemSideOffsets } = this.props
        const carousel = this.cRef.current

        // Resetting
        this.setState({ isDown: false })
        carousel.classList.remove('active')

        // handeling Threshold
        // (1) getting transValue
        const tempThresholdOffset = this.giveMeIntValOf(carousel.firstChild.style.transform)

        // (2) items width - 30(first & last item removed margins) - containerWidth(b/c of ending part)
        let end = Math.ceil(_data.length / this.props.row) * (itemWidth + (2 * itemSideOffsets)) - 30 - carousel.offsetWidth + 2
        if (end < 0)
            end = -2
        // (3) check if we passing from threshold ( handeling Snap To Sides )
        if (tempThresholdOffset < -end || tempThresholdOffset > 0) {
            this.setState({ isDown: false })
            for (let child of carousel.children) {
                child.style.cssText = `
                    transform: translateX(${tempThresholdOffset < 0 ? -end : 2}px);
                    transition: transform 0.5s cubic-bezier(.25,.72,.51,.96);
                `;
            }
        }


    }


    // helper Function
    giveMeIntValOf = (el) => {
        // extracting 20 from translateX(20px) and converting it to integer with parsInt
        return parseInt(el.replace('translateX(', '').replace('px)', ''), 10)
    }

    render() {
        const {
            _data,
            itemWidth,
            itemHeight,
            itemSideOffsets
        } = this.props

        const cWrapperStyle = {
            width: `${Math.ceil(_data.length / this.props.row) * (itemWidth + (2 * itemSideOffsets))}px`,
            height: `${itemHeight}px`
        }
        let rowCount = this.props.row ? this.props.row : 1
        let tmp = []
        for (let i = 0; i < rowCount; i++)
            tmp.push(i)
        return (
            <div
                className='carousel'
                ref={this.cRef}
                onMouseDown={this.handleMouseDown}
                onMouseLeave={this.handleMouseLeave}
                onMouseUp={this.handleMouseUp}
                onMouseMove={this.handleMouseMove}
                style={{
                    // transform: this.props.rotate ? 'rotate(-7deg)' : '',
                    overflow: this.props.rotate ? 'visible' : 'hidden'
                }}
            >
                {
                    tmp.map((i) => {
                        return <div
                            className={'cWrapper' + (i > 0 ? ' topmargin' : '')}
                            style={{
                                ...cWrapperStyle,
                                transform: 'translateX(' + (2) + 'px)',
                            }} key={"cWrapper" + i}>
                            {this.props.children.filter((it, j) => {
                                return j % rowCount == i
                            })}
                        </div>
                    })
                }

            </div>
        )
    }
}

export default Carousel